import fetch from 'isomorphic-unfetch';
import { print } from '../managers/Logger';
import { baseServerConfig } from '../store';
//DO NOT CHANGE THIS. ALL USERS WILL BE LOGGED OUT.
export default class BaseServer {
  constructor(url, auth) {
    this.url = url;
    this.auth = auth;
  }

  getAppType() {
    // Confusing I know but spaces exists on the movement.fm domain and represents the 3d spaces product.
    // ETA sits on the witheta domain and represents the old movement app...
    // This is confusing, we should change.
    return baseServerConfig.get() === 'movement' ? 'spaces' : 'movement';
  }

  setAuth(auth) {
    print('Setting server auth');
    print(auth);
    this.auth = auth;
  }

  setAppType(type) {
    if (type) {
      this.app_type = type;
    } else {
      if (global.window !== undefined) {
        const sessionStorage = window.sessionStorage;
        var context = sessionStorage.getItem('context');
        if (context && context === 'spaces') {
          this.app_type = 'spaces';
        } else {
          this.app_type = 'movement';
        }
      }
    }
  }

  async handleErrors(response) {
    if (!response.ok) {
      try {
        const errorBody = await response.json();
        const failBody = {
          code: -1,
          error: errorBody,
          statusCode: response.status,
        };
        return failBody;
      } catch (e) {
        return { code: -1, error: e, statusCode: response.status };
      }
    }
    print('Successful response:');
    return response.json();
  }

  async get(endpoint) {
    var newUrl = this.url + endpoint;
    var headers = {};
    print(endpoint);
    if (this.auth != null) {
      headers = {
        Authorization: 'Token ' + this.auth,
        'app-type': this.getAppType(),
      };
    }
    try {
      const data = await fetch(this.url + endpoint, {
        mode: 'cors',
        headers: headers,
      })
        .then((res) => this.handleErrors(res))
        .catch((error) => {
          return error;
        });

      return data;
    } catch {
      return undefined;
    }
  }

  async post(endpoint, body) {
    print(endpoint);
    print(body);
    var auth = null;
    if (this.auth != null) {
      auth = 'Token ' + this.auth;
    }
    const data = await fetch(this.url + endpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth,
        'app-type': this.getAppType(),
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return this.handleErrors(response);
      })
      .catch((error) => {
        return { code: -1, error: error };
      });

    return data;
  }

  async put(endpoint, body, callback, errorhandler) {
    var auth = null;
    if (this.auth != null) {
      auth = 'Token ' + this.auth;
    }
    const data = fetch(this.url + endpoint, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth,
        'app-type': this.getAppType(),
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return this.handleErrors(response);
      })
      .catch((error) => {
        return { code: -1, error: error };
      });

    return data;
  }

  async patch(endpoint, body) {
    print(endpoint);
    print(body);
    var auth = null;
    if (this.auth != null) {
      auth = 'Token ' + this.auth;
    }
    const data = await fetch(this.url + endpoint, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth,
        'app-type': this.getAppType(),
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return this.handleErrors(response);
      })
      .catch((error) => {
        return { code: -1, error: error };
      });

    return data;
  }

  async uploadMedia(endpoint, data) {
    print(data);
    var auth = null;
    if (this.auth != null) {
      auth = 'Token ' + this.auth;
    }

    return await fetch(this.url + endpoint, {
      method: 'post',
      headers: {
        Authorization: auth,
        'app-type': this.getAppType(),
      },
      body: data,
    })
      .then((res) => {
        return this.handleErrors(res);
      })
      .catch((error) => {
        return { code: -1, error: error };
      });
  }

  async nonJsonPost(endpoint, body) {
    print(this.url);
    print(endpoint);
    var auth = null;
    if (this.auth != null) {
      auth = 'Token ' + this.auth;
    }
    return await fetch(this.url + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: auth,
        'app-type': this.getAppType(),
      },
      body: body,
    })
      .then((response) => {
        return this.handleErrors(response);
      })
      .catch((error) => {
        return { code: -1, error: error };
      });
  }

  async delete(endpoint) {
    var auth = null;
    if (this.auth != null) {
      auth = 'Token ' + this.auth;
    }
    const data = await fetch(this.url + endpoint, {
      method: 'DELETE',
      headers: {
        Authorization: auth,
        'app-type': this.getAppType(),
      },
    })
      .then((response) => {
        return this.handleErrors(response);
      })
      .catch((error) => {
        return { code: -1, error: error };
      });

    return data;
  }
}
